<template>
  <div
    class="w-full absolute top-0 left-0 right-0 h-12 bg-secondary-purple text-white font-semibold grid grid-flow-col auto-cols-fr justify-center gap-x-1 px-2"
    style="z-index: 100"
  >
    <div></div>
    <div class="text-base flex items-center justify-center">
      <div class="h-12 w-12 flex justify-center items-center">
        <icon-base
          icon="sandbox-white"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        />
      </div>
      <div>
        {{ $t("ORGANIZATION.TEST_MODE.ALERT_MESSAGE") }}
      </div>
    </div>
    <div class="mr-9 flex items-center justify-end">
      <access-control :lendflow="true" :clients="true">
        <test-mode-modal v-if="showModal" :close="() => (showModal = false)" />
        <a href="" @click.prevent="showModal = true">
          {{ $t("ORGANIZATION.TEST_MODE.EXIT_TEXT") }}
        </a>
      </access-control>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import TestModeModal from "@/components/sidebar/TestModeModal.vue";
const showModal = ref(false);
</script>
